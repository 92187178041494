import React from 'react';

function ContactPage() {
    return (
        <div>
            <h1>Contact Us</h1>
            <p>koitech@koiTech.com</p>
        </div>
    );
}

export default ContactPage;
