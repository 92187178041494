import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css'
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import HomePage from './pages/Home'; // Assume this is your home page component
import AboutPage from './pages/About'; // The about page component you created
import ProductPage from './pages/Product'; // Assume this is your product page component
import ContactPage from './pages/Contact'; // The Contact page component you created


function App() {
    const [count, setCount] = useState(0)

    return (
        <BrowserRouter>
            <div className="app-container">
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/product" element={<ProductPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    )
}

export default App


