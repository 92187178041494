import React from 'react';

function AboutPage() {
    return (
        <div>
            <h1>About Us</h1>
            <h2 >At KOI TECH, we are a team of four women united by our passion for technology, particularly the transformative potential of AI in enhancing the health and wellness of society, with a special focus on aiding individuals experiencing memory loss. Our journey in the STEM fields has not only shaped our expertise but also our commitment to leveraging innovative solutions for real-world challenges. Guided by a vision to create a more inclusive and supportive environment, we are dedicated to empowering other women in STEM, fostering a community where diversity drives innovation. Our work is more than a mission; it's a promise to pave the way for future generations of women to thrive in technology and make lasting impacts on the health sector. Join us as we embark on this journey, harnessing the power of AI to create a healthier, more inclusive world.</h2>
        </div>
    );
}

export default AboutPage;
