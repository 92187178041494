// src/pages/Home.js
import React from 'react';

import './../assets/styles/HomePage.css'

function HomePage() {
    return (

        <div className="home-container">
            <section className="section" id="section-one">
                <h2>Welcome To KOI TECH</h2>
                <h1>Our Mission</h1>
            </section>
            <section className="section" id="section-two">
                <h2>Our Vision</h2>
                <p>This is the content for Section Two.</p>
            </section>
            <section className="section" id="section-three">
                <h2>Our Values</h2>
                <p>This is the content for Section Three.</p>
            </section>
        </div>
    );
}

export default HomePage;

